@import 'variables/bitters';
@import 'variables/neat';
@import 'variables/custom_pallete';

$baseColor: #5f5f5f;
$white: #fff;
$offWhite: #fafafa;
$blue: #3299cc;
$lightBlue: #eaf5fa;
$grey: #384955;
$lightGrey: #a6b1c2;
$bdrGrey: #eff0f2;
$bdrGrey1: #e3e7ec;
$brightBlue: #536dfe;
$pink: #ec407a;
$purple: #9c27b0;
$yellow: #fecd54;
$green: #2cd889;
$darkGreen: #4ca07a;
$red: #8f002c;
$lightred: #ffe9e9;
$darkgrey:#232323;
$lightBlack:#504B52;

$fs15: 15px;
$fs20: 20px;
$fs30: 30px;

$lh24: 24px;

$space10: 10px;
$space20: 20px;
$space30: 30px;


$text-uppercase: uppercase;
$text-capitalize: capitalize;

$bdr0: none;
$bdr1: 1px;

$absolute: absolute;
$relative: relative;
$fixed: fixed;

$theme-transition: all 0.3s ease;

$pointer: pointer;

$flex: flex;
$block: block;
$none: none;
$inlineBlock: inline-block;
$alignCenter: center;
$justifyCenter: center;
$justifyBetween: space-between;
$column: column;

$border-radius: 0.25rem;
$border-radius-sm: 0.2rem;
$border-radius-lg: 0.3rem;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$md: 767px;
$lg: 991px;
$xl: 1199px;
$xxl: 1439px;
$xxxl: 1599px;
$xxxxl: 1700px;
